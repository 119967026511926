// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-item {
    font-size: var(--adm-font-size-5);
    line-height: 1.5;
}

.media-title {
    font-size: var(--adm-font-size-7);
    color: var(--adm-color-primary);
    margin: 0 0 4px;
    text-shadow: #e3e3e3 2px 2px 5px;
}

.media-content {
    margin: 0 0 4px;
    text-indent: 2em;
}`, "",{"version":3,"sources":["webpack://./src/components/MediaItem/index.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;IACjC,+BAA+B;IAC/B,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".media-item {\r\n    font-size: var(--adm-font-size-5);\r\n    line-height: 1.5;\r\n}\r\n\r\n.media-title {\r\n    font-size: var(--adm-font-size-7);\r\n    color: var(--adm-color-primary);\r\n    margin: 0 0 4px;\r\n    text-shadow: #e3e3e3 2px 2px 5px;\r\n}\r\n\r\n.media-content {\r\n    margin: 0 0 4px;\r\n    text-indent: 2em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
