import './index.css'

export default ({media}) => {
    const {title, type} = media;
    
    const TextMediaContent = () => {
        const {text} = media;
        return <p className='media-content media-content-text'>{text}</p>
    }
    
    const MediaContent = () => {
        switch (type) {
            case "text":
                return <TextMediaContent/>
        }
        return <></>
    }
    
    return <div className='media-item'>
        <p className='media-title'>{title}</p>
        <MediaContent/>
    </div>
}