// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/AaGuDianKeBenSong.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
    --max-witdh: 980px;
    --adm-color-primary: #c3a07b;
    --adm-font-size-1: 16px;
    --adm-font-size-2: 17px;
    --adm-font-size-3: 18px;
    --adm-font-size-4: 19px;
    --adm-font-size-5: 20px;
    --adm-font-size-6: 21px;
    --adm-font-size-7: 22px;
    --adm-font-size-8: 23px;
    --adm-font-size-9: 24px;
    --adm-font-size-10: 25px;
}

@font-face {
    font-family: 'appFont';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

body, .page {
    /*height: 100%;*/
    /*overflow: hidden;*/
}

.page {
    margin: auto;
    max-width: var(--max-witdh);
    font-family: 'appFont';
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,4BAA4B;IAC5B,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,sBAAsB;IACtB,4CAA+C;AACnD;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,2BAA2B;IAC3B,sBAAsB;AAC1B","sourcesContent":["#root {\n    --max-witdh: 980px;\n    --adm-color-primary: #c3a07b;\n    --adm-font-size-1: 16px;\n    --adm-font-size-2: 17px;\n    --adm-font-size-3: 18px;\n    --adm-font-size-4: 19px;\n    --adm-font-size-5: 20px;\n    --adm-font-size-6: 21px;\n    --adm-font-size-7: 22px;\n    --adm-font-size-8: 23px;\n    --adm-font-size-9: 24px;\n    --adm-font-size-10: 25px;\n}\n\n@font-face {\n    font-family: 'appFont';\n    src: url(\"/public/fonts/AaGuDianKeBenSong.ttf\");\n}\n\nbody, .page {\n    /*height: 100%;*/\n    /*overflow: hidden;*/\n}\n\n.page {\n    margin: auto;\n    max-width: var(--max-witdh);\n    font-family: 'appFont';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
