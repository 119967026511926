import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Node from "./pages/node";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/node" element={<Node/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
