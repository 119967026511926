import {useEffect, useState} from "react";
import {supabase} from "../../supabase";
import {useSearchParams} from "react-router-dom";
import {Space, Tabs} from 'antd-mobile';
import './index.css';
import MediaItem from "../../components/MediaItem";

export default () => {
    const [searchParam] = useSearchParams();
    const nodeId = searchParam.get('id');
    const [data, setData] = useState({})

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const {data} = await supabase.from('std_node').select().eq('id', nodeId).single();
        setData(data)
    }

    const NodeMedias = () => {
        const medias = data?.data?.medias || []
        if (medias.length === 0) return <></>
        if (medias.length === 1) return <MediaBody medias={medias[0].children}/>
        return <Tabs style={{'--title-font-size': 'var(--adm-font-size-5)'}}>
            {medias.map((media) => (
                <Tabs.Tab title={media.title} key={media.title}>
                    <MediaBody medias={media.children}/>
                </Tabs.Tab>
            ))}
        </Tabs>
    }

    const MediaBody = ({medias}) => {
        return <div className='media-box'>
            {medias.map(media => <MediaItem key={media.title} media={media}/>)}
        </div>
    }

    const NodeHeader = () => {
        const {fields, contents} = data?.data || {};
        return (
            <div className="node-header">
                <p className="node-name">{data.name}</p>
                {fields && <Space direction="vertical">
                    {fields.map((e, index) => <p key={e}>{e}: {contents[index]}</p>)}
                </Space>}
            </div>
        )
    }

    return <div className='page node-page'>
        {/*<NodeHeader />*/}
        <NodeMedias/>
    </div>
}