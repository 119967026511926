// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.node-header p {
    margin: 0;
    font-size: 18px;
}

.node-header .node-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    background: linear-gradient(to bottom, red, yellow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.node-page .adm-tabs {
    display: flex;
    flex-direction: column;
}

.node-page .adm-tabs-tab {
    padding: 0 0 8px;
}

.node-page .adm-tabs-content {
    /*flex: 1;*/
    /*overflow-y: scroll;*/
}`, "",{"version":3,"sources":["webpack://./src/pages/node/index.css"],"names":[],"mappings":";AACA;IACI,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,mDAAmD;IACnD,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,sBAAsB;AAC1B","sourcesContent":["\r\n.node-header p {\r\n    margin: 0;\r\n    font-size: 18px;\r\n}\r\n\r\n.node-header .node-name {\r\n    font-size: 24px;\r\n    font-weight: bold;\r\n    margin-bottom: 10px;\r\n    background: linear-gradient(to bottom, red, yellow);\r\n    -webkit-background-clip: text;\r\n    -webkit-text-fill-color: transparent;\r\n}\r\n\r\n.node-page .adm-tabs {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.node-page .adm-tabs-tab {\r\n    padding: 0 0 8px;\r\n}\r\n\r\n.node-page .adm-tabs-content {\r\n    /*flex: 1;*/\r\n    /*overflow-y: scroll;*/\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
